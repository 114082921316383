:root {
    --bs-primary: #4FC0B4;
    --bs-primary-rgb: 79, 192, 180;
    --bs-secondary: #494949;
    --bs-secondary-rgb: 73, 73, 73;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.font-bebas {
    font-family: "Bebas Neue", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.font-roboto {
    font-family: 'Roboto', sans-serif !important;
}

.btn {
    --bs-btn-border-radius: 0;
}

.btn-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: #4FC0B4;
    --bs-btn-border-color: #4FC0B4;

    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #47CEB6;
    --bs-btn-hover-border-color: #47CEB6;

    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #47CEB6;
    --bs-btn-active-border-color: #47CEB6;

    --bs-btn-disabled-color: #333;
    --bs-btn-disabled-bg: #888;
    --bs-btn-disabled-border-color: #888;

    --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-shadow: none;
}

.form-control {
    border-radius: 3px;

    &:focus {
        border-color: #47CEB6;
        box-shadow: none;
    }
}

.form-check-input {
    &:checked {
        background-color: #47CEB6;
        border-color: #4FC0B4;
    }

    &:focus {
        box-shadow: 0 0 0 .25rem rgba(71, 206, 182, .25);
    }

}

.letter-spacing {
    letter-spacing: .5rem;
}

.C-494949 {
    color: #494949;
}

.C-47CEB6 {
    color: #47CEB6;
}

.C-4FC0B4 {
    color: #4FC0B4;
}

.C-42D4B7 {
    color: #42D4B7;
}

.C-9059A2 {
    color: #9059A2;
}

.C-CF5FF4 {
    color: #CF5FF4;
}

.C-D04E9B {
    color: #D04E9B;
}

.C-E82D72 {
    color: #E82D72;
}

.C-FC44AF {
    color: #FC44AF;
}

.box-top{    
    background: #494949;
    border-bottom-left-radius: 15px;
}


#header {
    #menu-list {
        font-size: 15px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1.5rem;

        .menu-list-item {
            a {
                color: #201F1F;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }

    #social-links {
        font-size: 24px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: .75rem;        

        .social-link-item {
            a {
                color: #A0A0A0;
                text-decoration: none;
                text-transform: uppercase;
                transition: color ease-in-out 300ms;
                &:hover{
                    color: #201F1F;
                }
            }
        }

    }
}

#main-home {
    #banner {
        background: #FFF url('../img/nuvens.png') -160px 210px no-repeat;
        background-size: cover;
        position: relative;

        .letter-spacing {
            letter-spacing: 1rem;
        }

        .font-size-2 {
            font-size: 3vw;
        }

        .font-size-1 {
            font-size: 12vw;
        }

        h1 {
            padding-top: 100px;
            position: relative;
            z-index: 2;
            margin-bottom: -8vw;
        }

        @media (max-width: 991px) {
            .letter-spacing {
                letter-spacing: 0.5rem;
            }

            .font-size-2 {
                font-size: 7vw;
            }

            .font-size-1 {
                font-size: 20vw;
            }

            h1 {
                padding-top: 50px;
                margin-bottom: -13vw;
            }
        }
    }

    #o-programa {
        position: relative;
        background: #494949;
        color: #FFF;
        padding: 8vw 0 270px;

        @media (max-width: 991px) {
            padding: 15vw 0 270px;
        }
    }

    #porque-existimos {
        position: relative;
        padding: 0 0 150px;

        .img-porque-existimos {
            margin-top: -220px;
        }
    }

    #atuacao-jornada {
        position: relative;
        background: #494949;
        color: #FFF;
        padding: 150px 0;

        .bg-atuacao {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }

    #boxes {
        padding: 100px 0;
    }

    #pluginovacao {
        position: relative;
        background: #494949;
        color: #FFF;
        padding: 150px 0;

        .fundo-pluginovacao {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: auto;
            height: 100%;

            @media (max-width: 767px) {
                top: 50px;
                left: -170px;
                width: 100%;
                height: auto;
                bottom: inherit;
            }
        }

        .title-pluginovacao {
            position: relative;
            z-index: 1;
            margin: -130px 0;
            font-size: 10rem;
            line-height: 1;
            width: 260px;
            line-break: anywhere;

            @media (max-width: 767px) {
                margin: -50px 0 50px;
                width: 100%;
                line-break: auto;
            }

            @media (max-width: 580px) {
                font-size: 9rem;
                margin: -50px 0 50px;
                width: 100%;
                line-break: anywhere;
            }
        }
    }

    #impacto-negocio {
        padding: 150px 0 0;
        position: relative;

        .fundo-impacto-negocio {
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: auto;
            z-index: -1;
        }
    }

    #acesso-talentos {
        position: relative;
        padding: 80px 0 80px;

        .img-talentos {
            margin-bottom: -79px;

            @media (max-width:991px) {
                margin-bottom: 0;
            }
        }

        #svg-line-4 {
            position: absolute;
            bottom: 30px;
            left: 0;
            width: 100%;
            z-index: -1;

            path {
                stroke-dasharray: 1900;
                stroke-dashoffset: 1900;
            }

            &.view path {
                animation-name: animate_svg_line_2;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: 0.5s;
                animation-fill-mode: forwards;
            }

            @keyframes animate_svg_line_2 {
                0% {
                    stroke-dashoffset: 1900;
                }

                100% {
                    stroke-dashoffset: 0;
                }
            }
        }

    }

    #inovacao {
        position: relative;
        padding: 80px 0 80px;

        .img-inovacao {
            margin-bottom: -79px;

            @media (max-width:991px) {
                margin-bottom: 0;
            }
        }

        #svg-line-3 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: -1;

            path {
                stroke-dasharray: 1900;
                stroke-dashoffset: 1900;
            }

            &.view path {
                animation-name: animate_svg_line_2;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: 0.5s;
                animation-fill-mode: forwards;
            }

            @keyframes animate_svg_line_2 {
                0% {
                    stroke-dashoffset: 1900;
                }

                100% {
                    stroke-dashoffset: 0;
                }
            }
        }

    }

    #networking-e-parcerias {
        position: relative;
        padding: 80px 0 80px;

        #svg-line-2 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: -1;

            path {
                stroke-dasharray: 2500;
                stroke-dashoffset: 2500;
            }

            &.view path {
                animation-name: animate_svg_line_2;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: 0.5s;
                animation-fill-mode: forwards;
            }

            @keyframes animate_svg_line_2 {
                0% {
                    stroke-dashoffset: 2500;
                }

                100% {
                    stroke-dashoffset: 0;
                }
            }
        }

    }

    #acesso-investimentos {
        position: relative;
        padding: 80px 0 150px;

        .fundo-acesso-investimento {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            transform: translate(0, 50%);
        }

        #svg-line-1 {
            position: absolute;
            bottom: 120px;
            left: 0;
            width: 100%;
            z-index: -1;

            path {
                stroke-dasharray: 1900;
                stroke-dashoffset: 1900;
            }

            &.view path {
                animation-name: animate_svg_line_1;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: 0.5s;
                animation-fill-mode: forwards;
            }

            @keyframes animate_svg_line_1 {
                0% {
                    stroke-dashoffset: 1900;
                }

                100% {
                    stroke-dashoffset: 0;
                }
            }
        }

    }

    #devenvolvendo-ecossistemas {
        padding: 150px 0;
        background: rgba(#D9D9D9, 0.68);
    }

    #quero-participar {
        padding: 150px 0;
        background: #494949;
        color: #FFF;
    }

    #contato {
        width: 100%;
        position: relative;
        padding: 150px 0;

        .contato-title {
            width: 230px;
        }

        .img-contato-foguete {
            position: absolute;
            top: 50px;
            right: calc(50% + 280px);
            width: auto;
            height: calc(100% - 50px);
            z-index: -1;
            opacity: 0;

            &.view {
                animation-name: foguete_top;
                animation-duration: 0.75s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in;
            }

            @media (max-width: 767px) {
                &.view {
                    animation-name: foguete_top_mobile;
                    animation-duration: 0.75s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-in;
                }

                width: 100px;
                height: auto;
                right: 60%;
            }
            @keyframes foguete_top_mobile {
                from {
                    opacity: 0;
                    top: 150px;
                    right: 80%;
                }
    
                to {
                    z-index: 2;
                    top: 50px;
                    opacity: 1;
                    width: 100px;
                    height: auto;
                    right: 60%;
                }
            }
    
            @keyframes foguete_top {
                from {
                    opacity: 0;
                    top: 80%;
                    right: 80%;
                }
    
                to {
                    z-index: 2;
                    opacity: 1;
                    top: 100px;
                    right: calc(50% + 280px);
                }
            }
        }

        .contato-wrap {
            position: relative;
            z-index: 2;
        }

    }
}

#main-fases {
    #pluginovacao {
        position: relative;
        background: #494949;
        color: #FFF;
        padding: 150px 0;

        .fundo-pluginovacao {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: auto;
            height: 100%;

            @media (max-width: 767px) {
                top: 50px;
                left: -170px;
                width: 100%;
                height: auto;
                bottom: inherit;
            }
        }

        .title-pluginovacao {
            position: relative;
            z-index: 1;
            margin: -130px 0;
            font-size: 10rem;
            line-height: 1;
            width: 260px;
            line-break: anywhere;

            @media (max-width: 767px) {
                margin: -50px 0 50px;
                width: 100%;
                line-break: auto;
            }

            @media (max-width: 580px) {
                font-size: 9rem;
                margin: -50px 0 50px;
                width: 100%;
                line-break: anywhere;
            }
        }
    }

    #fases {
        padding: 150px 0 0;
        position: relative;

        @media (max-width: 580px) {
            padding: 50px 0 0;
        }

        .fundo-nuvens {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            transform: translateY(-40%);
            z-index: -1;
        }

        .fase {
            padding: 100px 0;
            position: relative;
            counter-increment: fase;

            @media (max-width:580px) {
                padding: 50px 0;
            }

            &.fase-1 {
                padding-top: 0;
            }

            .fase-box {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 2vw;

                &::before {
                    content: counter(fase);
                    align-self: flex-start;
                    font-family: "Bebas Neue", sans-serif !important;
                    font-weight: 400 !important;
                    font-style: normal !important;
                    font-size: 7vw;
                    color: #ACACAC;
                    line-height: 1;
                    opacity: 0;
                    transform: scale(.25);
                    transition: transform ease-in 500ms, opacity ease-in 500ms;

                    @media (max-width: 767px) {
                        font-size: 10vw;
                    }

                    @media (max-width: 580px) {
                        font-size: 20vw;
                    }
                }

                &.view {
                    &::before {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }

            #svg-fase-1 {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: -1;

                path {
                    stroke-dasharray: 2200;
                    stroke-dashoffset: 2200;
                }

                &.view path {
                    animation-name: animate_svg_fase_1;
                    animation-duration: 3s;
                    animation-timing-function: linear;
                    animation-iteration-count: 0.5s;
                    animation-fill-mode: forwards;
                }

                @keyframes animate_svg_fase_1 {
                    0% {
                        stroke-dashoffset: 2200;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }

            #svg-fase-2-3 {
                position: absolute;
                top: -100px;
                left: 0;
                width: 100%;
                height: auto;
                z-index: -1;

                @media (max-width:580px) {
                    top: -50px;
                }

                path {
                    stroke-dasharray: 3200;
                    stroke-dashoffset: 3200;
                }

                &.view path {
                    animation-name: animate_svg_fase_2;
                    animation-duration: 3s;
                    animation-timing-function: linear;
                    animation-iteration-count: 0.5s;
                    animation-fill-mode: forwards;
                }

                @keyframes animate_svg_fase_2 {
                    0% {
                        stroke-dashoffset: 3200;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }

            #svg-fase-4-5 {
                position: absolute;
                top: -180px;
                left: 0;
                width: 100%;
                height: auto;
                z-index: -1;

                @media (max-width:580px) {
                    top: -50px;
                }

                path {
                    stroke-dasharray: 2200;
                    stroke-dashoffset: 2200;
                }

                &.view path {
                    animation-name: animate_svg_fase_4;
                    animation-duration: 3s;
                    animation-timing-function: linear;
                    animation-iteration-count: 0.5s;
                    animation-fill-mode: forwards;
                }

                @keyframes animate_svg_fase_4 {
                    0% {
                        stroke-dashoffset: 2200;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }

        }
    }
}

#main-fases-rodada-1 {
    #banner {
        padding: 100px 0;
        background: #494949;
        color: #FFF;       
    }

    .fases-rodada-box {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 2vw;
        counter-increment: faseRodada;

        &::before {
            content: counter(faseRodada);
            align-self: flex-start;
            font-family: "Bebas Neue", sans-serif !important;
            font-weight: 400 !important;
            font-style: normal !important;
            font-size: 7vw;
            color: #ACACAC;
            line-height: 1;
            opacity: 0;
            transform: scale(.25);
            transition: transform ease-in 500ms, opacity ease-in 500ms;

            @media (max-width: 767px) {
                font-size: 10vw;
            }

            @media (max-width: 580px) {
                font-size: 20vw;
            }
        }

        &.view {
            &::before {
                transform: scale(1);
                opacity: 1;
            }
        }
    }
}

#main-fases-rodada-2 {
    #banner {
        padding: 100px 0;
        background: #494949;
        color: #FFF;
    }

    .fase {
        width: 100%;
        overflow: hidden;

        .fase-text-wrap {
            position: relative;
            background: #E9E9E9;
            z-index: 20;
        }

        &.fase-1 {
            .fase-text-wrap::before {
                content: '';
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 50%;
                background: #E9E9E9;
            }
        }

        &.fase-2 {
            .fase-text-wrap::before {
                content: '';
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 50%;
                background: #E9E9E9;
            }
        }

        &.fase-3 {
            .fase-text-wrap::before {
                content: '';
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 50%;
                background: #E9E9E9;
            }
        }

        &.fase-4 {
            .fase-text-wrap::before {
                content: '';
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                left: 50%;
                background: #E9E9E9;
            }
        }
    }
}

#main-fases-rodada-3 {    
    #banner{
        padding: 80px 0;
        background: #FFF url('../img/nuvens.png') bottom 140% center no-repeat;
        .letter-spacing {
            letter-spacing: 1rem;
        }       

        .font-size-1 {
            font-size: 9vw;
        }
        .font-size-2 {
            font-size: 3vw;
        }
        .img-contato-foguete {
            width: auto;
            height: calc(100% + 100px);
            position: absolute;
            top: 100%;
            left: 0;
            transform: translateX(-50%);
            z-index: -1;
            opacity: 0;
            transition: all ease-in 500ms;

            &.view {
                opacity: 1;
                top: 0;
                left: 50%;   
                transform: translateX(-50%);
                z-index: 2;             
            }
        }
    }
    #ingressar{
        background: #494949;
        color: #FFF;
        padding: 80px 0;
    }
    #como-participar {
        .como-participar-item {            
            counter-increment: countParticipar;
            .como-participar-wrap{
                background: #E9E9E9;
                .como-participar-titulo {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: 2vw;
    
                    &::before {
                        content: counter(countParticipar);
                        align-self: flex-start;
                        font-size: 4.5vw;
                        line-height: 1;
                        opacity: 0;
                        transform: scale(.25);
                        transition: transform ease-in 500ms, opacity ease-in 500ms;
    
                        @media (max-width: 767px) {
                            font-size: 10vw;
                        }
    
                        @media (max-width: 580px) {
                            font-size: 20vw;
                        }
                    }
                    &.view {
                        &::before {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    #etapas {
        background: #494949;
        color: #FFF;
        padding: 80px 0;
    }

    #requisitos {
        padding: 80px 0;

        .requisito-item {
            background: #E9E9E9;
        }
    }

    #categorias {
        background: #494949;
        color: #FFF;
        padding: 80px 0;
    }
}

#footer {
    background: #494949;
    color: #FFF;
    padding: 80px 0;

    #social-links {
        font-size: 32px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 1rem;

        .social-link-item {
            a {
                color: #FFF;
                text-decoration: none;
                text-transform: uppercase;
            }
        }

    }
}

.animate {
    &.animate-fade {
        opacity: 0;
        transition: opacity ease-in 500ms;

        &.view {
            opacity: 1;
        }
    }

    &.animate-fade-top {
        opacity: 0;
        transform: translateY(30%);
        transition: opacity ease-in 700ms, transform ease-in 500ms;

        &.view {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &.animate-fade-left {
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity ease-in 700ms, transform ease-in 500ms;

        &.view {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &.animate-fade-right {
        opacity: 0;
        transform: translateX(50%);
        transition: opacity ease-in 700ms, transform ease-in 500ms;

        &.view {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &.animate-fade-scale {
        opacity: 0;
        transform: scale(.75);
        transition: opacity ease-in 700ms, transform ease-in 500ms;

        &.view {
            transform: scale(1);
            opacity: 1;
        }
    }
}